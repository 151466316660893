import React from "react";

const baseStyle = "inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-empirica-500";
const inactiveStyle = "border-gray-300 shadow-sm text-gray-700 bg-white";

export function StageRectangles({ signal }) {

  var displayText = "Decision Stage";
  switch(signal) {
    case "decision":
      displayText = "Decision Stage";
      break;
    case "question":
      displayText = "Question Stage";
      break;
    case "answer":
      displayText = "Answer Stage";
      break;
    case "firing":
      displayText = "Firing Stage";
      break;
  }

  return (
    <div className="flex space-x-4" style={{marginTop:"10px"}}>
      <div
        className={`${baseStyle} ${inactiveStyle}`}
        style={{ width: "15vw", height: "3vw", borderStyle:"solid", fontSize:"1.2vw"}}
      >
        {displayText}
      </div>
    </div>
  );
}
import React, { useState, useReducer } from "react";
import { usePlayer, useStage, useGame, useRound, useStageTimer } from "@empirica/core/player/classic/react";
import BoardComponent from "../components/BoardComponent.jsx";
import ShipsRemainingComponent from "../components/ShipsRemainingComponent.jsx";
import { Button } from "../components/Button.jsx";
import { SpotterBoardComponent } from "../components/SpotterBoardComponent.jsx";
import { HistoryComponent } from "../components/HistoryComponent.jsx";
import { noQuestion, timeoutAnswer } from "../../../utils/systemText.js";
import { SendMessageIntro } from "../../../utils/SendMessage.js";
import { InstructionBox } from "../components/InstructionBox.jsx";
import { flushSync } from "react-dom";

export function IntroStage() {
    const player = usePlayer();
    const round = useRound();
    const stage = useStage();
    const game = useGame();
    const timer = useStageTimer();

    const [showTextbox, setShowTextbox] = useState(false);
    const [showBackButton, setShowBackButton] = useState(true);

    if (game.get("questionEveryTime") && showBackButton) {
        setShowTextbox(true);
        setShowBackButton(false);
    }

    function handleAskQuestion() {
        if (!showTextbox) {
            setShowTextbox(true);
        } else {
            askQuestion();
        }
    }

    function answerQuestion(inputType, hardcodedAnswer) {
        if (player.stage.get("question") == noQuestion) {
            var inputText = "";
        } else {
            switch (inputType) {
                case "free":
                    var inputText = document.getElementById('answer').value;
                    inputText = inputText.trim();
                    break;
                case "categorical":
                    var inputText = hardcodedAnswer;
            }
        }

        if (inputText.length > 1 && inputText.length < 20) {
            SendMessageIntro(inputText, "answer", player);
            player.stage.set("textStage",player.stage.get("textStage")+1)
        } 
        if (inputText.length == 0) {
            if (player.stage.get("question") == noQuestion) {
                inputText = noQuestionAnswer;
            } else {
                inputText = timeoutAnswer;
            }
            SendMessageIntro(inputText, "answer", player);
            player.stage.set("textStage",player.stage.get("textStage")+1)
        }
    }


    function getPossibleAnswers() {
        if (game.get("categoricalAnswers")) {
            return (
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Button className="m-5" primary={true} handleClick={() => alert("[Tutorial] The red ship is vertical: please answer 'No'.")}>Yes</Button>
                <Button className="m-5" handleClick={() => answerQuestion("categorical", "no")}>No</Button>
            </div>
            );
        } else {
            return (
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <input type="text" id="answer" name="answer" style={{height: "50px"}} autoComplete="off"/>
                <Button className="m-5" handleClick={() => answerQuestion("free")}>Answer Question</Button>
            </div>);
        }
    }
    
    function askQuestion() {
        var inputText = document.getElementById('question').value;
        inputText = inputText.trim();
        if (inputText == "Is the red ship horizontal?" || inputText == "is the red ship horizontal?" || inputText == "is the red ship horizontal" || inputText == "Is the red ship horizontal") {
            SendMessageIntro(inputText, "question", player);
            SendMessageIntro("No", "answer", player);
            player.stage.set("textStage",player.stage.get("textStage")+1);
        } else {
            alert("[Tutorial] Please ask 'Is the red ship horizontal?'");
        }
    }


    if (player.stage.get("textStage") == 6 && player.stage.get("textboxHidden") == undefined) {
        setShowTextbox(false);
        player.stage.set("textboxHidden",true);
    }


    if (player.stage.get("textStage") == 7 && player.stage.get("forcedUpdate") == undefined) {
        window.location.reload(false);
        player.stage.set("forcedUpdate",true);
    }

    switch (player.stage.get("textStage")) {
        case 1:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <BoardComponent 
                    init_tiles={player.stage.get("occTiles")}
                    ships={player.stage.get("ships")}
                    />   
                    </div>
                </div>);
        case 2:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <BoardComponent 
                    init_tiles={player.stage.get("occTiles")}
                    ships={player.stage.get("ships")}
                    />   
                    </div> 
                </div>);
        case 3:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <ShipsRemainingComponent 
                      shipsStatus={player.stage.get("shipStatus")}
                    />
                    <BoardComponent 
                    init_tiles={player.stage.get("occTiles")}
                    ships={player.stage.get("ships")}
                    />   
                    </div>
                </div>);
        case 4:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <ShipsRemainingComponent 
                        shipsStatus={player.stage.get("shipStatus")}
                    />
                    <BoardComponent 
                    init_tiles={player.stage.get("occTiles")}
                    ships={player.stage.get("ships")}
                    />   
                    </div>
                </div>);
        case 5:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <ShipsRemainingComponent 
                            shipsStatus={player.stage.get("shipStatus")}
                        />
                        <BoardComponent 
                        init_tiles={player.stage.get("occTiles")}
                        ships={player.stage.get("ships")}
                        />   
                    </div>
                    <div className="flex justify-center" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    {showTextbox && (
                            <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
                            <input type="text" id="question" name="question" style={{height: "50px"}} autoComplete="off"/>
                                    <Button className="m-5" handleClick={handleAskQuestion}>
                                    {showTextbox ? "Submit Question" : "Ask Question"}
                                    </Button>
                            </div>
                    )     }
                    { !showTextbox && (
                        <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}><Button className="m-5" handleClick={handleAskQuestion}>
                                Ask Question
                            </Button>
                        <p style={{fontSize: "20px"}}>or</p>
                            <Button className="m-5" primary={true} handleClick={() => alert("[Tutorial] Just for the tutorial, please ask a question!")}>Ready to Fire!</Button>
                            </div>)
                    }
                    { (showTextbox && showBackButton) 
                    ? (<div> <Button className="m-5" handleClick={() => setShowTextbox(false)}>Back</Button></div>)
                    : <div></div>
                    }
                    </div>
                </div>);
        case 6:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <ShipsRemainingComponent 
                            shipsStatus={player.stage.get("shipStatus")}
                        />
                        <BoardComponent 
                        init_tiles={player.stage.get("occTiles")}
                        ships={player.stage.get("ships")}
                        />   
                        <HistoryComponent
                  externalMsg={player.stage.get("messages")}
              /> 
                    </div>
                    <div className="flex justify-center" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    
                        <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}><Button className="m-5"  primary={true} handleClick={() => alert("[Tutorial] Please choose 'Ready to fire'!")}>
                                Ask Question
                            </Button>
                        <p style={{fontSize: "20px"}}>or</p>
                            <Button className="m-5" handleClick={() => player.stage.set("textStage",player.stage.get("textStage")+1)}>Ready to Fire!</Button>
                            </div>
                    </div>
                </div>);
        case 7:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <ShipsRemainingComponent 
                            shipsStatus={player.stage.get("shipStatus")}
                        />
                        <BoardComponent 
                        init_tiles={player.stage.get("occTiles")}
                        ships={player.stage.get("ships")}
                        />   
                        <HistoryComponent
                  externalMsg={player.stage.get("messages")}
              /> 
                    </div>
                </div>);
        case 8:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <ShipsRemainingComponent 
                            shipsStatus={player.stage.get("shipStatus")}
                        />
                    <SpotterBoardComponent
                                occ_tiles={player.stage.get("occTiles")}
                                init_tiles={player.stage.get("trueTiles")}
                                ships={player.stage.get("ships")}
                                    />
                                    <HistoryComponent
                  externalMsg={player.stage.get("messages")}
              /> 
                    </div>
                </div>);
        case 9:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <ShipsRemainingComponent 
                            shipsStatus={player.stage.get("shipStatus")}
                        />
                    <SpotterBoardComponent
                                occ_tiles={player.stage.get("occTiles")}
                                init_tiles={player.stage.get("trueTiles")}
                                ships={player.stage.get("ships")}
                                    />
                    <HistoryComponent
                  externalMsg={player.stage.get("messages")}
              /> 
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}><p style={{margin: "10px", fontSize:"1.2vw"}}>The captain asked the following question: <i>"{player.stage.get("question")}"</i>. Please answer it below:</p>
            {getPossibleAnswers()}</div>
                </div>);
        case 10:
            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <InstructionBox textStage={player.stage.get("textStage")}/>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <ShipsRemainingComponent 
                            shipsStatus={player.stage.get("shipStatus")}
                        />
                    <SpotterBoardComponent
                                occ_tiles={player.stage.get("occTiles")}
                                init_tiles={player.stage.get("trueTiles")}
                                ships={player.stage.get("ships")}
                                    />
                    <HistoryComponent
                  externalMsg={player.stage.get("messages")}
              /> 
                    </div>
                </div>);

    }

} 
